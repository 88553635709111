import React, { FC, useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

import ShopMessage from 'components/ShopMessage';
import Layout from 'components/Layout';
import NFButton from 'components/common/NFButton';
import BreadCrumbs from 'components/BreadCrumbs';
import SymptomsHeader from 'components/SymptomsHeader';
import SymptomsFaq from 'components/SymptomsFaq';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import OrderedList from 'components/OrderedList';
import HighlightedLinks from 'components/HighlightedLinks';
import ArticleSelector from 'components/ArticleSelector';
import FeaturedArticles from 'components/FeaturedArticles';
import RelatedProducts from 'components/RelatedProducts';
import GatsbyImage from 'components/common/GatsbyImage';
import Video from 'components/common/Video';
import HighlightedList from 'components/HighlightedList';
import WhatToDoInfo from 'components/WhatToDoInfo';
import ColumnList from 'components/ColumnList';
import ExtendedFeaturedArticles from 'components/ExtendedFeaturedArticles';
import ExpertVideosList from 'components/ExpertVideosList';
import ArticleMedicalInfo from 'components/ArticleMedicalInfo';
import Callout from 'components/common/Callout';
import SimpleSection from 'components/PodcastsPageComponents/SimpleSection';
import TagLinksList from 'components/common/TagLinksList';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseExtendedFeaturedArticles } from 'components/ExtendedFeaturedArticles/parsers';
import parseCalloutSection from 'templates/AdvancedChildrenHubArticlePage/parsers';

import { ISymptoms } from './model';
import './symptoms.scss';
import './symptomsOverride.scss';

const elements: AppPageDataTypes.TBodyElements = {
  'Symptoms Text Block': ({ content }, keyId) => (
    <Container fluid>
      <DangerouslySetInnerHtml key={keyId} html={content} />
    </Container>
  ),
  'Article Ordered List': ({ orderedList, title, limit, pointsColor }, keyId) => (
    <Container fluid>
      <OrderedList
        key={keyId}
        orderedList={orderedList}
        title={title}
        limit={limit}
        pointsColor={pointsColor}
      />
    </Container>
  ),
  'Article Highlighted Links': ({ title, list, lineColor }, keyId) => (
    <Container fluid>
      <HighlightedLinks key={keyId} title={title} lineColour={lineColor} points={list} />
    </Container>
  ),
  'Featured Image': ({ featuredImage, imgAlt }, keyId) =>
    featuredImage?.gatsbyImage?.childImageSharp ? (
      <Container fluid>
        <GatsbyImage
          className="featured-img"
          key={keyId}
          fluid={featuredImage.gatsbyImage.childImageSharp.fluid}
          alt={imgAlt}
        />
      </Container>
    ) : null,
  'Video Block': ({ videoLink, previewImg, btnPlay }, keyId) => (
    <Container fluid>
      <Video
        key={keyId}
        btnPlay={btnPlay?.svg?.content}
        previewImg={previewImg[0].properties}
        video={videoLink}
      />
    </Container>
  ),
  'Highlighted List': ({ title, highlightedList, image, imgAlt, pointsColor, position }, keyId) => (
    <Container fluid>
      <HighlightedList
        key={keyId}
        title={title}
        list={highlightedList}
        img={image}
        imgPosition={position}
        imgAltText={imgAlt}
        pointsColor={pointsColor}
      />
    </Container>
  ),
  'WhatToDo Table': ({ whatToDoTable, isMobile }, keyId) => (
    <WhatToDoInfo key={keyId} items={whatToDoTable} isMobile={isMobile} />
  ),
  'Symptoms FAQ': ({ symptomsFAQ, accordionWrapperHeaderClasses, faqSectionTitle }, keyId) => (
    <>
      {faqSectionTitle ? (
        <Container fluid>
          <h2 className="symptoms-faq__title">FAQ</h2>
        </Container>
      ) : null}
      <SymptomsFaq
        key={keyId}
        symptomsFAQ={symptomsFAQ}
        wrapperHeaderClassNames={accordionWrapperHeaderClasses}
      />
    </>
  ),
  'Column List': ({ columnList }, keyId) => <ColumnList key={keyId} columnList={columnList} />,
  'Shop message': ({ shopMessage }, keyId) => {
    const shopMessageProperties = shopMessage?.[0]?.properties;

    return shopMessage?.[0]?.properties ? (
      <ShopMessage
        key={keyId}
        linkText={shopMessageProperties?.shopMessageLink?.[0]?.name}
        linkUrl={extractUrlFromMultiPicker(shopMessageProperties?.shopMessageLink)}
        title={shopMessageProperties?.shopMessageTitle}
        blocks={shopMessageProperties?.shopMessageIcons}
        shopMessageBgColor={
          shopMessageProperties?.shopMessageBgColor?.[0]?.properties?.colorPicker?.label
        }
        shopMessageFontColor={
          shopMessageProperties?.shopMessageFontColor?.[0]?.properties?.colorPicker?.label
        }
      />
    ) : null;
  },
  'First Custom Articles Slider': ({
    firstCustomArticlesByTag,
    carouselControls,
    firstCustomArticlesSlider,
  }) => {
    return (
      <FeaturedArticles
        bgColor={firstCustomArticlesSlider[0].properties.featuredArticlesBgColor}
        title={firstCustomArticlesSlider[0].properties.featuredArticlesSectionTitle}
        subtitle={firstCustomArticlesSlider[0].properties.featuredArticlesSectionSubtitle || ''}
        btn={
          firstCustomArticlesSlider[0].properties.featuredArticlesCTAButtonData?.length
            ? {
                ...parseNFButton(
                  firstCustomArticlesSlider[0].properties.featuredArticlesCTAButtonData[0]
                    .properties
                ),
              }
            : undefined
        }
        articles={firstCustomArticlesSlider[0].properties.featuredArticlesData}
        mode={firstCustomArticlesSlider[0].properties.featuredArticlesSelectedArticleMode}
        articlesByTag={firstCustomArticlesByTag.nodes}
        carouselControls={carouselControls}
      />
    );
  },
  'Second Custom Articles Slider': ({
    secondCustomArticlesByTag,
    carouselControls,
    secondCustomArticlesSlider,
  }) => {
    return (
      <FeaturedArticles
        bgColor={secondCustomArticlesSlider[0].properties.featuredArticlesBgColor}
        title={secondCustomArticlesSlider[0].properties.featuredArticlesSectionTitle}
        subtitle={secondCustomArticlesSlider[0].properties.featuredArticlesSectionSubtitle || ''}
        btn={
          secondCustomArticlesSlider[0].properties.featuredArticlesCTAButtonData?.length
            ? {
                ...parseNFButton(
                  secondCustomArticlesSlider[0].properties.featuredArticlesCTAButtonData[0]
                    .properties
                ),
              }
            : undefined
        }
        articles={secondCustomArticlesSlider[0].properties.featuredArticlesData}
        mode={secondCustomArticlesSlider[0].properties.featuredArticlesSelectedArticleMode}
        articlesByTag={secondCustomArticlesByTag.nodes}
        carouselControls={carouselControls}
      />
    );
  },
  'Expert Reviewed Videos': (
    { videoSectionTitle, videoSectionSubtitle, videoListWithExperts, carouselControls },
    keyId
  ) => {
    return (
      <ExpertVideosList
        key={keyId}
        title={videoSectionTitle}
        subtitle={videoSectionSubtitle}
        carouselControls={carouselControls}
        videoListWithExperts={videoListWithExperts}
      />
    );
  },
};

const StructureToClassName = {
  'Article Ordered List': 'article-ordered-list-block-wrapper',
  'Article Highlighted Links': 'article-highlighted-links-block-wrapper',
  'Featured Image': 'featured-image-block-wrapper',
  'Video Block': 'video-block-block-wrapper',
  'Highlighted List': 'highlighted-list-block-wrapper',
  'WhatToDo Table': 'what-to-do-table-block-wrapper',
  'Symptoms FAQ': 'symptoms-faq-block-wrapper',
  'Column List': 'column-list-block-wrapper',
  'Shop message': 'shop-message-block-wrapper',
  'First Custom Articles Slider': 'featured-articles-block-wrapper',
  'Second Custom Articles Slider': 'featured-articles-block-wrapper',
  'Expert Reviewed Videos': 'experts-videos-review-block-wrapper',
};

interface ISymptomsPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[]; relatedProducts: string[] };
  data: ISymptoms;
}

const SymptomsPage: FC<ISymptomsPage> = ({
  pageContext: { breadcrumbs, relatedProducts: relatedProductsLinks },
  data,
}) => {
  const { isMobile } = useScreenRecognition();
  const {
    featuredArticlesByTag,
    firstCustomArticlesByTag,
    secondCustomArticlesByTag,
    relatedProducts,
    carouselControls,
    page: {
      nodes: [
        {
          relatedProductsConfig,
          relatedArticles,
          symptomsHeader,
          title,
          pageName,
          body,
          simpleSection,
          calloutSection,
          questionBarBorderColor,
          symptomsTextBlockBulletColor,
          symptomsTextBlockBgColor,
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          lang,
          extentedRelatedArticles,
          medicalInfo,
          tags,
        },
      ],
    },
    prevSymptom,
    nextSymptom,
    symptomsSettings: {
      symptomsSettingsNextLinkTitle,
      symptomsSettingsPreviousLinkTitle,
      symptomsBtn,
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
    extendedFeaturedArticlesPainAdviceByLink,
    extendedFeaturedArticlesPainAdviceByTag,
    extendedFeaturedArticlesSymptomsByLink,
    extendedFeaturedArticlesSymptomsByTag,
  } = data;

  const parsedExtentedRelatedArticles = extentedRelatedArticles?.[0]
    ? parseExtendedFeaturedArticles({
        extendedFeaturedArticles: extentedRelatedArticles?.[0]?.properties,
        articlesByLink: [
          ...extendedFeaturedArticlesPainAdviceByLink?.nodes,
          ...extendedFeaturedArticlesSymptomsByLink?.nodes,
        ],
        articlesByTag: [
          ...extendedFeaturedArticlesPainAdviceByTag?.nodes,
          ...extendedFeaturedArticlesSymptomsByTag?.nodes,
        ],
        carouselControls,
      })
    : undefined;

  const questionBarBorderLabel = questionBarBorderColor
    ? questionBarBorderColor[0]?.properties.colorPicker?.label
    : undefined;
  const accordionWrapperHeaderClasses = classNames('', {
    [`${questionBarBorderLabel}-default-bg`]: questionBarBorderLabel,
  });
  const textAreaBlockBulletColor = symptomsTextBlockBulletColor?.[0]?.properties.colorPicker?.label;
  const textAreaBlockBgColor = symptomsTextBlockBgColor?.[0]?.properties.colorPicker?.label;
  const textAreaBlockClasses = classNames('text-area-block-wrapper', {
    [`${textAreaBlockBgColor}-default-bg`]: textAreaBlockBgColor,
    [`${textAreaBlockBulletColor}-default-additional-bg`]: textAreaBlockBulletColor,
  });
  const grayPageContainerClass = pageName
    ? `${pageName.toLowerCase()}-container symptoms-page symptoms-page--gray`
    : 'symptoms-page symptoms-page--gray';
  const normalPageContainerClass = pageName
    ? `${pageName.toLowerCase()}-container symptoms-page`
    : 'symptoms-page';

  const renderSimpleSection = useMemo(
    () =>
      simpleSection && simpleSection?.length > 0 ? (
        <SimpleSection {...simpleSection[0].properties} />
      ) : null,
    [simpleSection]
  );

  const renderCalloutSection = useMemo(() => {
    if (!calloutSection?.length) return null;

    const {
      callout,
      tagsLists,
      featuredArticles,
      featuredArticlesCTAButton,
    } = parseCalloutSection({ ...calloutSection[0].properties });

    return (
      <Callout
        title={callout.title}
        description={callout.description}
        calloutColor={callout.color}
        tagsLists={tagsLists}
        featuredArticles={featuredArticles}
        featuredArticlesCTAButton={featuredArticlesCTAButton}
        settings={{ carouselControls }}
      />
    );
  }, [calloutSection, carouselControls]);

  const isSymptomPagePartOfAdvancedChildrenHub = !!renderCalloutSection;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div className={grayPageContainerClass}>
        <Container fluid>
          {breadcrumbs ? <BreadCrumbs data={breadcrumbs} /> : null}
          {title ? <h1>{title}</h1> : null}
          {isSymptomPagePartOfAdvancedChildrenHub && tags?.filter((tag) => tag?.relatedPage) ? (
            <TagLinksList
              tagsFormat="smallLabel"
              tagsList={tags
                .filter(({ relatedPage }) => relatedPage)
                .map(({ title: name, relatedPage: link }) => ({ name, link }))}
            />
          ) : null}
        </Container>
        {medicalInfo?.[0]?.properties ? (
          <ArticleMedicalInfo
            title={medicalInfo[0].properties.title}
            body={medicalInfo[0].properties.bodyText}
          />
        ) : null}
        <Container fluid>
          {symptomsHeader?.[0]?.properties ? (
            <SymptomsHeader
              img={symptomsHeader[0].properties.image}
              imageAlt={symptomsHeader[0].properties.imageAlt}
              imgShiftedLeft={symptomsHeader[0].properties.imgShiftedLeft}
              imgRotateShiftedMd={symptomsHeader[0].properties.imgRotateShiftedMd}
              imgRotateShiftedSm={symptomsHeader[0].properties.imgRotateShiftedSm}
              symptomsList={symptomsHeader[0].properties.list}
            />
          ) : null}
        </Container>
      </div>

      <div className={normalPageContainerClass}>
        <div className="symptoms-page__content-text">
          {body?.length > 0
            ? body.map(({ properties, structure }, index) => {
                const keyId = `${structure}_${index}`;
                const isSymptomsBlock = structure === 'Symptoms Text Block';
                const isWhatToDoTable = structure === 'WhatToDo Table';
                const isSymptomsFAQ = structure === 'Symptoms FAQ';
                const isFirstCustomArticles = structure === 'First Custom Articles Slider';
                const isSecondCustomArticles = structure === 'Second Custom Articles Slider';
                const isExpertReviewedVideos = structure === 'Expert Reviewed Videos';
                const wrapperProps = {
                  ...(isSymptomsBlock && {
                    className: textAreaBlockClasses,
                  }),
                  ...(StructureToClassName[structure] && {
                    className: StructureToClassName[structure],
                  }),
                };
                const componentProps = {
                  ...(isWhatToDoTable && {
                    isMobile,
                  }),
                  ...(isSymptomsFAQ && {
                    accordionWrapperHeaderClasses,
                    faqSectionTitle: properties.faqSectionTitle,
                  }),
                  ...(isFirstCustomArticles && {
                    firstCustomArticlesByTag,
                    carouselControls,
                  }),
                  ...(isSecondCustomArticles && {
                    secondCustomArticlesByTag,
                    carouselControls,
                  }),
                  ...(isExpertReviewedVideos && {
                    carouselControls,
                  }),
                };

                return elements[structure] ? (
                  <div {...wrapperProps} key={keyId}>
                    {elements[structure]({ ...properties, ...componentProps }, keyId)}
                  </div>
                ) : null;
              })
            : null}
        </div>

        {isSymptomPagePartOfAdvancedChildrenHub ? (
          <>
            {renderSimpleSection}
            {renderCalloutSection}
          </>
        ) : prevSymptom.nodes?.[0] || nextSymptom.nodes?.[0] ? (
          <div className="article-selector-frame">
            <Container fluid>
              <Row className="justify-content-center">
                <ArticleSelector
                  col={[prevSymptom.nodes?.[0], nextSymptom.nodes?.[0]]}
                  prevTitle={symptomsSettingsPreviousLinkTitle}
                  nextTitle={symptomsSettingsNextLinkTitle}
                />
                {symptomsBtn?.length && isMobile ? (
                  <NFButton {...parseNFButton(symptomsBtn[0].properties)} />
                ) : null}
              </Row>
            </Container>
          </div>
        ) : null}

        {relatedArticles?.[0]?.properties ? (
          <FeaturedArticles
            bgColor={relatedArticles[0].properties.featuredArticlesBgColor}
            title={relatedArticles[0].properties.featuredArticlesSectionTitle}
            subtitle={relatedArticles[0].properties.featuredArticlesSectionSubtitle || ''}
            btn={
              relatedArticles[0].properties.featuredArticlesCTAButtonData?.length
                ? {
                    ...parseNFButton(
                      relatedArticles[0].properties.featuredArticlesCTAButtonData[0].properties
                    ),
                  }
                : undefined
            }
            articles={relatedArticles[0].properties.featuredArticlesData}
            mode={relatedArticles[0].properties.featuredArticlesSelectedArticleMode}
            articlesByTag={featuredArticlesByTag.nodes}
            carouselControls={carouselControls}
          />
        ) : null}
        {parsedExtentedRelatedArticles ? (
          <ExtendedFeaturedArticles {...parsedExtentedRelatedArticles} />
        ) : null}

        {relatedProducts.nodes?.length ? (
          <RelatedProducts
            title={relatedProductsConfig?.[0]?.properties?.relatedProductsTitle}
            showBtn={relatedProductsConfig?.[0]?.properties?.shopBtnsShow === '1'}
            btn={
              relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.length
                ? parseNFButton(
                    relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.[0]?.properties
                  )
                : undefined
            }
            products={relatedProducts.nodes}
            productsLinks={relatedProductsLinks}
            carouselControls={carouselControls}
            listingName={pageName}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $articleTagId: Int
    $firstCustomArticlesByTag: Int
    $secondCustomArticlesByTag: Int
    $link: String!
    $relatedProducts: [String]
    $nextSymptomId: String
    $prevSymptomId: String
    $pageIdRegex: String
    $extendedFeaturedArticlesByTagIds: [Int]
    $extendedFeaturedArticlesByLinks: [String]
  ) {
    ...FragmentExtendedFeaturedArticlesData
    relatedProducts: allUmbracoProduct(
      filter: { link: { nin: [$link], in: $relatedProducts }, lang: { eq: $lang } }
      limit: 6
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    featuredArticlesByTag: allUmbracoArticles(
      filter: { tags: { elemMatch: { id: { eq: $articleTagId } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    firstCustomArticlesByTag: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { eq: $firstCustomArticlesByTag } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    secondCustomArticlesByTag: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { eq: $secondCustomArticlesByTag } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    prevSymptom: allUmbracoSymptoms(filter: { umbracoId: { eq: $prevSymptomId } }) {
      nodes {
        title
        link
      }
    }
    nextSymptom: allUmbracoSymptoms(filter: { umbracoId: { eq: $nextSymptomId } }) {
      nodes {
        title
        link
      }
    }
    symptomsSettings(lang: { eq: $lang }) {
      ...FragmentSymptomsSettings
    }
    page: allUmbracoSymptoms(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        ...FragmentSeo
        relatedProductsConfig {
          properties {
            ...FragmentRelatedProductsConfig
          }
        }
        title
        link
        lang
        relatedArticles {
          properties {
            ...FragmentFeaturedArticles
          }
        }
        ...FragmentMedicalInfo
        symptomsHeader {
          properties {
            list {
              properties {
                subText
                text
              }
            }
            image {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              svg {
                content
              }
            }
            imageAlt
            imgRotateShiftedMd
            imgRotateShiftedSm
            imgShiftedLeft
          }
        }
        questionBarBorderColor {
          ...FragmentColorProps
        }
        symptomsTextBlockBulletColor {
          ...FragmentColorProps
        }
        symptomsTextBlockBgColor {
          ...FragmentColorProps
        }
        pageName
        body {
          properties {
            ...FragmentVideoBlock
            imgAlt
            firstCustomArticlesSlider {
              properties {
                ...FragmentFeaturedArticles
              }
            }
            secondCustomArticlesSlider {
              properties {
                ...FragmentFeaturedArticles
              }
            }
            videoSectionTitle
            videoSectionSubtitle
            videoListWithExperts {
              properties {
                video {
                  properties {
                    ...FragmentVideoBlock
                  }
                }
                expertOpinion {
                  properties {
                    ...FragmentExpertOpinion
                  }
                }
              }
            }
            featuredImage {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            content
            faqSectionTitle
            title
            lineColor {
              ...FragmentColorProps
            }
            list {
              properties {
                text
              }
            }
            limit
            highlightedList {
              properties {
                text
              }
            }
            position
            pointsColor {
              ...FragmentColorProps
            }
            image {
              fallbackUrl
              svg {
                content
              }
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 330) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            whatToDoTable {
              properties {
                listItem {
                  properties {
                    name
                  }
                }
                redBullet
                title
                isOpenedOnMobile
              }
            }
            symptomsFAQ {
              properties {
                title
                content
                isOpen
              }
            }
            columnList {
              properties {
                list
              }
            }
            shopMessage {
              properties {
                shopMessageTitle
                shopMessageIcons {
                  properties {
                    label
                    icon {
                      fallbackUrl
                      svg {
                        content
                      }
                    }
                  }
                }
                shopMessageLink {
                  url
                  name
                }
                shopMessageBgColor {
                  ...FragmentColorProps
                }
                shopMessageFontColor {
                  ...FragmentColorProps
                }
              }
            }
            ...FragmentOrderedListI
          }
          structure
        }
        simpleSection {
          properties {
            ...FragmentSimpleSection
          }
        }
        calloutSection {
          properties {
            ...FragmentAdvancedChildrenHubCalloutSection
          }
        }
        extentedRelatedArticles {
          properties {
            ...FragmentExtendedFeaturedArticles
          }
        }
        tags {
          title
          relatedPage
        }
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    pageLangs: allUmbracoSymptoms(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default SymptomsPage;
